import { useMemo } from 'react'
import config from 'config'
import links from 'links'
import { usePathname, useSearchParams } from 'router'

import { useUser } from 'models/user'
import { useNavigationItems } from 'models/admin/useNavigation'
import { useDevice } from 'device'
import { checkWebView } from 'helpers'

import { openLoginModal } from 'compositions/modals'


const useMenuItems = () => {
  const { user, hasAuth, handleLogin, handleLogout, isUserFetching } = useUser()
  const { adminAccess } = user || {}

  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { isMobile } = useDevice()

  const { navigationItems } = useNavigationItems()

  const currentUrl = `${pathname}${Object.keys(searchParams).length ? `?${(new URLSearchParams(searchParams)).toString()}` : ''}`

  const adminModuleLink = useMemo(() => {
    if (navigationItems?.length) {
      return navigationItems[0]?.to || navigationItems[0]?.subNav?.[0]?.to
    }
  }, [ navigationItems ])

  const items = useMemo(() => {
    const isNoProfileCity = (
      config.cityName === 'Пуровский район'
      || config.cityName === 'Заречный'
      // || config.cityName === 'Мичуринск'
    )

    const isTest = config.cityName === 'Саров'

    const { isWebView } = checkWebView(navigator.userAgent)
    const isAminLinkHidden = isWebView && isMobile

    const result = []

    if (hasAuth && !isNoProfileCity) {
      result.push({
        title: 'Личный кабинет',
        to: links.reactProfile.root,
      })
    }

    if (adminAccess && !isAminLinkHidden) {
      result.push({
        title: 'Административный раздел',
        to: (!__DEV__ && adminModuleLink) ? adminModuleLink : links.admin.root,
      })
    }

    if (!config.isLegacyAuth) {
      result.push({
        title: hasAuth ? 'Выход' : 'Вход',
        onClick: hasAuth ? handleLogout : handleLogin,
      })
    }
    else {
      result.push({
        title: hasAuth ? 'Выход' : 'Вход',
        to: hasAuth ? links.logout : null,
        onClick: hasAuth
          ? null
          : () => openLoginModal({ redirectLink: currentUrl }),
      })
    }

    return result
  }, [ adminAccess, adminModuleLink, hasAuth, currentUrl, handleLogin, handleLogout, isMobile ])

  return items
}


export default useMenuItems
