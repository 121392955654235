import React from 'react'
import cx from 'classnames'

import { ActionButton } from 'components/inputs'
import { DropdownMenu } from 'components/navigation'
import useNavigation from 'compositions/Header/util/useNavigation'


type NavigationProps = {
  className?: string
  navigationRef: React.MutableRefObject<HTMLDivElement>
}

const MenuButton = ({ className, title, to, toTab, subItems, isOpen }) => (
  <ActionButton
    className={className}
    title={title}
    size="c16"
    color="titanic"
    to={to || null}
    toTab={toTab || null}
    arrow={subItems ? ( isOpen ? 'up' : 'down') : null}
  />
)

const Navigation: React.FC<NavigationProps> = ({ className, navigationRef }) => {
  const { items } = useNavigation()

  return (
    <div ref={navigationRef} className={cx('flex min-w-1', className)}>
      {
        items.map((item, index) => {
          const { title, to, toTab, subItems, sections } = item

          return (
            <DropdownMenu
              className="min-w-1"
              key={index}
              items={subItems || []}
              sections={sections || null}
              placement="bottomLeft"
              style="blank"
            >
              <MenuButton
                className={cx('whitespace-nowrap', { 'ml-20px' : index })}
                title={title}
                to={to}
                toTab={toTab}
                subItems={subItems}
              />
            </DropdownMenu>
          )
        })
      }
    </div>
  )
}


export default Navigation
