import links from 'links'
import config from 'config'
import { useDevice } from 'device'
import { usePathname } from 'router'
import React, { useEffect, useRef } from 'react'

import { useMunicipalities } from 'models/city'

import Desktop from './components/Desktop/Desktop'
import Mobile from './components/Mobile/Mobile'

import { useMenuItems } from './util'


const Header = () => {
  const items = useMenuItems()
  const { isMobile, isTablet } = useDevice()

  const isRegion = !config.isLegacyAuth
  const { isRootDomain } = useMunicipalities()
  const withHeaderMenu = !(isRegion && isRootDomain)

  const pathname = usePathname()
  const prevPathname = useRef('')

  useEffect(() => {
    const from = prevPathname.current

    if (/^\/immortals\/./.test(from) && pathname === links.immortals.root) {
      // no need to scroll
    }
    else {
      window.scroll(0, 0)
    }

    prevPathname.current = pathname
  }, [ pathname ])

  return (
    <>
      <div id="announcement" />
      {
        (isMobile || isTablet) ? (
          <Mobile items={items} withHeaderMenu={withHeaderMenu} />
        ) : (
          <Desktop items={items} withHeaderMenu={withHeaderMenu} />
        )
      }
    </>
  )
}


export default Header