import React from 'react'

import { ActionButton } from 'components/inputs'

import MobileMenuModal, { openMobileMenuModal } from '../MobileMenuModal/MobileMenuModal'


type BurgerMenuProps = {
  className?: string
}

const BurgerMenu: React.FC<BurgerMenuProps> = (props) => {
  const { className } = props

  return (
    <>
      <ActionButton
        className={className}
        color="titanic"
        icon="menu/menu_20"
        onClick={openMobileMenuModal}
      />
      <MobileMenuModal />
    </>
  )
}


export default BurgerMenu
