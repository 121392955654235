import React, { useCallback, useEffect, useRef, useState } from 'react'
import config from 'config'
import { useDevice } from 'device'

import { useUser } from 'models/user'
import { getImage } from 'models/mapLayer'

import { Avatar } from 'components/dataDisplay'
import { ActionButton } from 'components/inputs'

import s from './ProfileButton.scss'


type ProfileButtonProps = {
  isOpen?: boolean
  rootRef: React.MutableRefObject<HTMLDivElement>
  parentRef: React.MutableRefObject<HTMLDivElement>
  navigationRef: React.MutableRefObject<HTMLDivElement | null>
}

const ProfileButton: React.FC<ProfileButtonProps> = ({ isOpen, rootRef, parentRef, navigationRef }) => {
  const { user, hasAuth } = useUser()
  const { isMobile } = useDevice()

  const { login, pic, image, email, firstName, lastName } = user || {}

  const title = (firstName || lastName) ? `${firstName || ''}${lastName ? ' ' + lastName : ''}` : email

  const textRef = useRef<HTMLDivElement>(null)

  const [ isTruncated, setIsTruncated ] = useState(false) // is overflow-ellipsis active

  const hover = useCallback(() => {
    if (isTruncated) {
      parentRef.current.classList.add(s.hovered)
      rootRef.current.style.position = 'relative'
      navigationRef?.current?.classList?.remove('justify-center')
      navigationRef?.current?.classList?.add('justify-start')
    }
  }, [ isTruncated, parentRef, rootRef, navigationRef ])

  const unHover = useCallback(() => {
    if (!isOpen && parentRef && rootRef) {
      parentRef.current.classList.remove(s.hovered)
      rootRef.current.style.position = 'static'
      navigationRef?.current?.classList?.add('justify-center')
      navigationRef?.current?.classList?.remove('justify-start')
    }
  }, [ parentRef, rootRef, navigationRef, isOpen ])

  useEffect(() => {
    const isEllipsisActive = (element) => {
      return (element.offsetWidth < element.scrollWidth)
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const textNode = entry.target
        const isNewTruncated = isEllipsisActive(textNode)
        if (isNewTruncated !== isTruncated) {
          setIsTruncated(isNewTruncated)
        }
      }
    })

    if (textRef?.current) {
      resizeObserver.observe(textRef.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [ isTruncated ])

  return (
    <div
      className="flex ml-16px min-w-0"
      onMouseEnter={hover}
      onMouseLeave={unHover}>
      {
        hasAuth ? (
          <>
            <Avatar
              image={
                config.isLegacyAuth
                  ? (pic ? getImage(pic) : null)
                  : (image ? getImage(image) : null)
              }
              size={24}
              userName={title}
            />
            {
              !isMobile && (
                <ActionButton
                  iconClassName="no-shrink"
                  textRef={textRef}
                  className="ml-4px min-w-0"
                  title={title}
                  color="titanic"
                  arrow={isOpen ? 'up' : 'down'}
                />
              )
            }
          </>
        ) : (
          <ActionButton
            iconClassName="no-shrink"
            className="min-w-0"
            textRef={textRef}
            title={isMobile ? null : 'Профиль'}
            icon="main/avatar_16"
            color="titanic"
            arrow={isMobile ? null : (isOpen ? 'up' : 'down')}
          />
        )
      }
    </div>
  )
}


export default ProfileButton
