import React, { useRef } from 'react'
import links from 'links'
import config from 'config'
import { useDevice } from 'device'
import { checkWebView } from 'helpers'

import { useUser } from 'models/user'

import { Logo } from 'components/dataDisplay'
import { SkipContent } from 'components/ada'
import { ActionButton } from 'components/inputs'
import { DropdownMenu, Href } from 'components/navigation'
import { Bone, WidthContainer } from 'components/layout'

import { openSearchModal } from 'compositions/modals/SearchModal/SearchModal'
import Notifications from 'compositions/admin/mapLayers/AdminHeader/Notifications/Notifications'

import ProfileButton from '../ProfileButton/ProfileButton'

import Navigation from './components/Navigation/Navigation'

import s from './Desktop.scss'


const Desktop = ({ items, withHeaderMenu }) => {
  const { isTablet } = useDevice()
  const { hasAuth, isUserFetching } = useUser()
  const { isIos } = checkWebView(!__SERVER__ ? navigator.userAgent : '')

  const rootRef = useRef<HTMLDivElement>()
  const parentRef = useRef<HTMLDivElement>()
  const navigationRef = useRef<HTMLDivElement>()

  return (
    <>
      <SkipContent contentId="root" />
      <header>
        <WidthContainer>
          <div ref={rootRef} className="relative flex items-center my-20px">
            <Href className="flex no-shrink items-center" to={links.home}>
              <Logo className={s.logo} />
            </Href>
            {
              withHeaderMenu ? (
                <Navigation navigationRef={navigationRef} className="ml-20px flex no-shrink grow justify-center" />
              ) : (
                <div className="flex-1" />
              )
            }
            <div ref={parentRef} className="ml-20px flex items-center min-w-0">
              {
                withHeaderMenu && (
                  <ActionButton
                    id="search"
                    // title={isTablet ? null : 'Поиск'}
                    icon={hasAuth ? 'main/search_20' : 'main/search_16'}
                    color="titanic"
                    onClick={openSearchModal}
                  />
                )
              }
              {
                hasAuth && (
                  <Notifications
                    className="ml-16px"
                  />
                )
              }
              {
                !isUserFetching ? (
                  <DropdownMenu className="flex items-center min-w-0" items={items} placement="bottomRight" style="blank">
                    <ProfileButton rootRef={rootRef} parentRef={parentRef} navigationRef={navigationRef} />
                  </DropdownMenu>
                ) : (
                  <Bone className="ml-16px radius-8" w={115} h={24} />
                )
              }
            </div>
          </div>
        </WidthContainer>
      </header>
    </>
  )
}


export default Desktop
