import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { usePathname } from 'router'

import { Knob, KnobProps } from 'components/inputs'
import { Href } from 'components/navigation'
import { Text } from 'components/dataDisplay'

import s from './Service.scss'


export type ServiceProps = {
  className?: string
  icon: KnobProps['icon']
  to: string
  title: string
}

const Service: React.FC<ServiceProps> = (props) => {
  const { className, icon, title, to } = props

  const pathname = usePathname()

  const { isMobile } = useDevice()

  const active = to !== '/' && pathname.includes(to)

  return (
    <Href className={cx('flex flex-col items-center', className)} to={to}>
      <Knob
        style={active ? 'primary' : 'secondary'}
        icon={icon}
        size={isMobile ? 64 : 72}
        circle
      />
      <Text
        className={cx(s.text, 'mt-8px text-center mw-full', {
          'opacity-72': !active,
        })}
        message={title}
        size="c13"
        color="titanic"
        wrapWord
      />
    </Href>
  )
}


export default Service
