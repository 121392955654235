import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { usePathname } from 'router'
import { modalVisibility, ModalVisibilityProps, openModal } from 'modal'

import { Icon, Logo, Text } from 'components/dataDisplay'
import useNavigation from 'compositions/Header/util/useNavigation'

import { Modal } from 'components/feedback'
import { ButtonBase } from 'components/inputs'

import s from './MobileMenuModal.scss'


type MenuButtonProps = Header.NavigationItem & {
  className?: string
  selected?: boolean
  onClick?: () => void
}

const MenuButton: React.FC<MenuButtonProps> = (props) => {
  const { className, title, to, subItems, sections, selected, onClick } = props

  const { isMobile } = useDevice()

  return (
    <ButtonBase
      className={cx(className, 'flex w-full justify-start py-12px')}
      to={to}
      onClick={onClick}
    >
      <Text
        className={cx('opacity-72 text-left overflow-ellipsis webkit-box', {
          'flex-1': !isMobile,
          'mr-8px': isMobile,
        })}
        message={title}
        size="c16"
        color="titanic"
      />
      {
        Boolean(sections || subItems) && (
          isMobile ? (
            <Icon
              className="opacity-48"
              name={selected ? 'arrow/arrow-up_8' : 'arrow/arrow-down_8'}
              color="titanic"
            />
          ) : (
            <Icon
              className="opacity-48"
              name="arrow/arrow-right_12"
              color="rocky"
            />
          )
        )
      }
    </ButtonBase>
  )
}

const MobileMenuModal: React.FC<ModalVisibilityProps> = (props) => {
  const { closeModal } = props
  const pathname = usePathname()
  const initialPathname = useRef(pathname)

  const { isMobile } = useDevice()
  const { items } = useNavigation()
  const [ selected, setSelected ] = useState(null)

  useEffect(() => {
    if (pathname !== initialPathname.current) {
      closeModal()
    }
  }, [ pathname, closeModal ])

  const selectedSubItems = useMemo(() => {
    return selected?.subItems?.map(({ title, to, sectionId }, index) => {
      const isSectionTitleVisible = Boolean(
        selected?.sections?.[sectionId]
        && (
          !index
          || selected.subItems[index - 1]?.sectionId !== sectionId
        )
      )

      const sectionTitle = isSectionTitleVisible
        && selected.sections.find((section) => section.id === sectionId)?.title

      return {
        title,
        to,
        sectionTitle,
      }
    })
  }, [ selected ])

  return (
    <Modal
      className={cx(s.modal, 'items-start justify-start flex-row')}
      title=""
      color={isMobile ? 'white' : 'rush'}
      closeModal={closeModal}
    >
      {
        isMobile ? (
          <div className="pb-20px bg-white">
            <Logo className="mt-12px ml-24px mb-12px" />
            {
              items.map(({ title, to, subItems, sections }, index) => (
                <Fragment key={index}>
                  <MenuButton
                    className="px-24px"
                    title={title}
                    to={to}
                    selected={selected?.title === title}
                    subItems={subItems}
                    sections={sections}
                    onClick={() => subItems ? setSelected({ subItems, sections, title }) : null}
                  />
                  {
                    Boolean(selected?.title === title) && (
                      selectedSubItems?.map(({ title, to, sectionTitle }, index) => (
                        <Fragment key={index}>
                          {
                            Boolean(sectionTitle) && (
                              <Text
                                className={cx('opacity-48 ml-40px mb-4px', {
                                  'mt-4px': !index,
                                  'mt-20px': index,
                                })}
                                size="s13-r"
                                color="titanic"
                                message={sectionTitle}
                              />
                            )
                          }
                          <MenuButton
                            className={cx('pl-40px pr-36px')}
                            title={title}
                            to={to}
                          />
                        </Fragment>
                      ))
                    )
                  }
                </Fragment>
              ))
            }
          </div>
        ) : (
          <div className="flex h-full">
            <div className="flex-1 bg-white pb-12px">
              <Logo className="mt-20px ml-32px" />
              <div className="mt-32px">
                {
                  items.map(({ title, to, subItems, sections }, index) => (
                    <MenuButton
                      key={index}
                      className={cx('pl-32px pr-16px', {
                        [s.selected]: selected?.title === title,
                      })}
                      title={title}
                      to={to}
                      subItems={subItems}
                      sections={sections}
                      onClick={() => subItems ? setSelected({ subItems, sections, title }) : null}
                    />
                  ))
                }
              </div>
            </div>
            <div className="flex-1  pb-12px">
              <div style={{ marginTop: `${32 + 40 + 20}px` }}>
                {
                  selectedSubItems?.map(({ title, to, sectionTitle }, index) => (
                    <Fragment key={index}>
                      {
                        Boolean(sectionTitle) && (
                          <Text
                            className={cx('opacity-48 pl-24px mb-4px', {
                              'mt-20px': index,
                            })}
                            size="s13-r"
                            color="titanic"
                            message={sectionTitle}
                          />
                        )
                      }
                      <MenuButton
                        className={cx('pl-24px pr-36px')}
                        title={title}
                        to={to}
                      />
                    </Fragment>
                  ))
                }
              </div>
            </div>
          </div>
        )
      }
    </Modal>
  )
}

export const openMobileMenuModal = () => openModal('MobileMenuModal')

export default modalVisibility('MobileMenuModal', MobileMenuModal)