import { IconName } from 'components/dataDisplay'
import links, { routeData } from 'links'
import { usePublicModules } from 'models/city'
import { getModuleTitle } from 'models/settings'
import { useMemo } from 'react'

import { ServiceProps } from '../components/Service/Service'


const useServicesSlider = () => {
  const { modules, isFetching } = usePublicModules()

  const services: ServiceProps[] = useMemo(() =>([
    {
      title: 'Обеспечение мобильности',
      to: links.accessibility.root,
      icon: 'transport/descent_32',
    },
    {
      title: 'Участковые пункты',
      to: links.police.root,
      icon: 'places/police_32',
    },
    {
      title: 'Избирательные округа',
      to: links.elections.root,
      icon: 'government/vote_32',
    },
    // {
    //   title: 'Достопримечательности города',
    //   to: links.home,
    //   icon: 'places/public-space_32',
    // },
    {
      title: 'Учреждения социальной сферы',
      to: links.institutions.root,
      icon: 'places/buildings_32',
    },
    {
      title: 'Медицина',
      to: links.medicine.root,
      icon: 'government/medicine_32',
    },
    {
      title: 'Достопримечательности',
      to: links.culture.root, // Vue
      icon: 'government/culture_32',
    },
    {
      title: 'Уборка города',
      to: links.cleaning,
      icon: 'ecology/separate_32',
    },
    {
      title: 'Точки сбора ТКО',
      to: links.controlTKO,
      icon: 'ecology/tko_32',
    },
    {
      title: 'Территории обслуживания УК',
      to: links.serviceArea.root,
      icon: 'map/layers_32',
    },
    {
      title: 'Земляные работы',
      to: links.excavation.root,
      icon: 'cadastral/cleaning-territory_32',
    },
    {
      title: 'Оперативные оповещения',
      to: links.alerts.root,
      icon: 'communication/telephone_32',
    },
  ].map((item) => ({
    ...item,
    title: getModuleTitle(modules, routeData[item.to]?.tag),
  }))), [ modules ])

  return { services, isFetching }
}

export default useServicesSlider